import React, { useEffect, useState } from "react";

import MetaData from "../../../layout/MetaData";

import { useAlert } from "react-alert";

import Sidebar from "../../Sidebar";
import axios from "axios";

const UpdateAnswer = ({ history, match }) => {
  const alert = useAlert();
  const [values, setValues] = useState({
    name: "",
    question_id: "",
    loading: false,
  });

  const id = match.params.id;

  useEffect(() => {
    loadAnswer();
  }, []);
  const loadAnswer = async () => {
    const { data } = await axios.get(`/api/admin/answer/${id}`);
    const data1 = data.answer[0];
    if (data1) {
      setValues(data1);
    }
  };
  const getAuth = async () => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const { data } = await axios.get("/api/admin/auth-admin", config);
    } catch (error) {
      alert.error(error.response.data);
      history.push("/login");
    }
  };

  useEffect(() => {
    try {
      getAuth();
    } catch (error) {}
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log();
    try {
      setValues({ ...values, loading: true });
      const { data } = await axios.put(`/api/admin/update-answer/${id}`, {
        ...values,
      });
      setValues({ ...values, loading: false });
      alert.success("Option Updated");
      history.push(`/admin/answers/${values.question_id}`);
    } catch (error) {
      alert.error(error.response.data);
      setValues({ ...values, loading: false });
    }
  };

  return (
    <div>
      <MetaData title={"Edit Option"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>
        <div className="col-12 col-md-10">
          <div>
            <div className="wrapper my-5">
              <form
                className="shadow-lg"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <h1 className="mb-4">Edit Option</h1>

                <div className="form-group">
                  <label htmlFor="description_field">Answer</label>
                  <textarea
                    className="form-control"
                    id="description_field"
                    rows="7"
                    cols="5"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  ></textarea>
                </div>

                <button
                  id="login_button"
                  type="submit"
                  className="btn btn-block py-3"
                  disabled={values.loading ? true : false}
                >
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateAnswer;
