import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";

import Loader from "../../layout/Loader";

import MetaData from "../../layout/MetaData";

import { useAlert } from "react-alert";

import Sidebar from "../Sidebar";
import axios from "axios";
import Resizer from "react-image-file-resizer";

const AllLectures = ({ history, match }) => {
  const alert = useAlert();
  const [lectures, setLectures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [moduleDetail, setModuleDetail] = useState({});
  const [isDeleted, setIsDeleted] = useState(false);
  const [isQuizAdded, setIsQuizAdded] = useState(false);
  const moduleId = match.params.moduleId;
  const getAuth = async () => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const { data } = await axios.get("/api/admin/auth-admin", config);
    } catch (error) {
      alert.error(error.response.data);
      history.push("/login");
    }
  };

  useEffect(() => {
    try {
      getAuth();
    } catch (error) {}
  }, []);
  const getLectures = async () => {
    const { data } = await axios.get(`/api/admin/all-lectures/${moduleId}`);

    setLectures(data.lectures);
  };
  const getModuleDetail = async () => {
    const { data } = await axios.get(`/api/admin/module-detail/${moduleId}`);

    setModuleDetail(data.moduleDetail);
    setLoading(false);
  };
  const lectureDeleteHandler = async (lectureId, key) => {
    if (window.confirm("Are you sure?")) {
      try {
        // await axios.post("/api/s3/delete-pdf", { key: key });
        await axios.delete(`/api/admin/lecture/${lectureId}`);
        setIsDeleted(!isDeleted);
        alert.success("lecture deleted successfully");
        history.push(`/admin/all-lectures/${moduleId}`);
      } catch (error) {
        alert.error("lecture deletion failed");
      }
    }
  };

  const quizAddHandler = async (moduleId) => {
    try {
      await axios.post("/api/admin/add-quiz", { module_id: moduleId });
      setIsQuizAdded(true);
      alert.success("Quiz Added successfully");
      history.push(`/admin/all-lectures/${moduleId}`);
    } catch (error) {
      alert.error("Adding Quiz failed");
    }
  };
  useEffect(() => {
    setLoading(true);
    try {
      getLectures();
      getModuleDetail();
    } catch (error) {
      alert.error(error);
      setLoading(false);
    }
  }, [alert, isDeleted, isQuizAdded]);

  const setLecture = () => {
    const data = {
      columns: [
        {
          label: "Id",
          field: "id",
          sort: "asc",
        },
        {
          label: "Title",
          field: "title",
          sort: "asc",
        },
        {
          label: "Type",
          field: "type",
          sort: "asc",
        },

        {
          label: "Document",
          field: "document",
          sort: "asc",
        },

        {
          label: "Action",
          field: "actions",
        },
      ],
      rows: [],
    };

    {
      lectures &&
        lectures.forEach((lecture) => {
          data.rows.push({
            title: lecture.title,
            id: lecture.quiz ? (
              <div>
                <Link to={`/admin/questions/${lecture.id}`} className="py-1 ">
                  {lecture.id}
                </Link>
              </div>
            ) : (
              lecture.id
            ),

            type: lecture.type,
            document: lecture.quiz ? (
              "No Doc"
            ) : (
              <a
                href={`https://chaloexam.s3.amazonaws.com/${lecture.document}`}
                target="_blank"
              >
                {" "}
                Click to See Doc{" "}
              </a>
            ),
            actions: (
              <div>
                {!lecture.quiz && (
                  <div>
                    <Link
                      to={`/admin/lecture/${lecture.id}`}
                      className="btn btn-primary py-1 px-2 "
                    >
                      <i className="fa fa-pencil"></i>
                    </Link>
                    <button
                      title="Delete"
                      className="btn btn-danger py-1 px-2 mx-2"
                      type="button"
                      onClick={() =>
                        lectureDeleteHandler(lecture.id, lecture.document)
                      }
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                )}
              </div>
            ),
          });
        });
    }

    return data;
  };

  return (
    <div>
      <MetaData title={"Lectures"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="col-12 col-md-10">
            <div>
              <h2 className="my-2">Lectures</h2>
              <h4 className="my-2">
                {moduleDetail && moduleDetail.name + "/" + moduleDetail.subject}
              </h4>
              <h5 className="my-2">
                {moduleDetail &&
                  moduleDetail.semester + "/" + moduleDetail.course}
              </h5>
              <h6 className="">
                {moduleDetail &&
                  moduleDetail.university +
                    " - " +
                    moduleDetail.state +
                    " (" +
                    moduleDetail.stream +
                    ")"}
              </h6>
              <Link
                to={`/admin/add-lecture/${moduleId}`}
                className="btn btn-success py-1 px-2 my-2 "
              >
                Add Lectures
              </Link>
              {(lectures && lectures.some((e) => e.quiz)) ||
              moduleDetail.type === "n" ? (
                ""
              ) : (
                <button
                  type="button"
                  onClick={() => quizAddHandler(moduleId)}
                  className="btn btn-success py-1 px-2 my-2 mx-2"
                >
                  Add quiz
                </button>
              )}
              <Link
                to={`/admin/all-modules/${moduleDetail.subject_id}`}
                className="btn btn-info py-1 px-2 my-2 mx-2"
              >
                Back to Modules
              </Link>
              <div>
                <MDBDataTable
                  data={setLecture()}
                  className="py-1"
                  bordered
                  striped
                  hover
                  paging={false}
                />
              </div>{" "}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllLectures;
