import React, { useEffect, useState } from "react";

import MetaData from "../layout/MetaData";

import { useAlert } from "react-alert";

import Sidebar from "./Sidebar";
import axios from "axios";
import { Redirect } from "react-router-dom";

const AdminLogin = ({ history, match }) => {
  const alert = useAlert();
  const [values, setValues] = useState({
    email: "",
    password: "",
    loading: false,
  });

  const getAuth = async (config) => {
    try {
      const { data } = await axios.get("/api/admin/auth-admin", config);

      if (data.success) {
        history.push("/");
      }
    } catch (error) {
     
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };

    try {
      getAuth(config);
    } catch (error) {
      alert.error(error);
    }
  }, []);
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //  console.log(universityId);
    try {
      setValues({ ...values, loading: true });
      const { data } = await axios.post("/api/admin/admin-login", {
        ...values,
      });
      localStorage.setItem("token", JSON.stringify(data.token));
      //   alert.success("Admin Added Successfully");
      setValues({ ...values, loading: false });
      history.push(`/`);
    } catch (error) {
      alert.error(error.response.data);
      setValues({ ...values, loading: false });
    }
  };

  return (
    <div>
      <MetaData title={"Admin Login"} />
      <div className="row">
        <div className="col-12 col-md-12">
          <div>
            <div className="wrapper my-5">
              <form
                className="shadow-lg"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <h1 className="mb-4">Login</h1>

                <div className="form-group">
                  <label htmlFor="email_field">email</label>
                  <input
                    type="text"
                    id="name_field"
                    className="form-control"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email_field">password</label>
                  <input
                    type="password"
                    id="name_field"
                    className="form-control"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                </div>
                <button
                  id="login_button"
                  type="submit"
                  className="btn btn-block py-3"
                  disabled={values.loading ? true : false}
                >
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
