import React, { useEffect, useState } from "react";

import MetaData from "../../layout/MetaData";

import { useAlert } from "react-alert";

import Sidebar from "../Sidebar";
import axios from "axios";
import Resizer from "react-image-file-resizer";

const UpdateSemester = ({ history, match }) => {
  const alert = useAlert();

  const [values, setValues] = useState({
    name: "",
    description: "",
    price: "",
    offer_price: "",
    monthly_subscription_price:"",
    loading: false,
    course_id: "",
  });

  const [schemes, setSchemes] = useState([]);

  const [scheme, setScheme] = useState("1");
  const [uploadButtonText, setUploadButtonText] = useState("Upload Pdf");
  const [pdf, setPdf] = useState("");
  const semesterId = match.params.id;
  const getAuth = async () => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const { data } = await axios.get("/api/admin/auth-admin", config);
    } catch (error) {
      alert.error(error.response.data);
      history.push("/login");
    }
  };

  const loadSchemes = async () => {
    const { data } = await axios.get(`/api/admin/all-schemes`);
    const data1 = data.schemes;

    if (data1) setSchemes(data1);
  };

  useEffect(() => {
    try {
      loadSchemes();
      getAuth();
    } catch (error) {}
  }, []);
  useEffect(() => {
    loadSemester();
  }, []);
  const loadSemester = async () => {
    const { data } = await axios.get(`/api/admin/semester/${semesterId}`);
    const data1 = data.semester[0];
    if (data1) {
      setValues(data1);
      setScheme(data1.scheme_id);
      setPdf(data1.syllabus)
      setUploadButtonText(data1.syllabus)
    }
  };
  const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const handlePdf = async (e) => {
  let file = e.target.files[0];
  var url = await toBase64(file);

  setUploadButtonText(e.target.files[0].name);
  setValues({ ...values, loading: true });

  try {
    let { data } = await axios.post("/api/s3/upload-pdf", {
      document: url,
    });
    alert.success("document uploaded succesfully");
    setValues({ ...values, loading: false });
    setPdf(data.Key);
  } catch (error) {
    console.log(error);
    setValues({ ...values, loading: false });
  }
};

const handlePdfRemove = async () => {
  try {
    setValues({ ...values, loading: true });
    const res = await axios.post("/api/s3/delete-pdf", { key: pdf });
    setPdf("");

    setUploadButtonText("Upload Pdf");
    alert.success("document deleted succefully");
    setValues({ ...values, loading: false });
  } catch (error) {
    console.log(error);
    setValues({ ...values, loading: false });
    alert.error("document deletion failed");
  }
};
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //  console.log(universityId);
    try {
      setValues({ ...values, loading: true });
      const { data } = await axios.post(
        `/api/admin/update-semester/${semesterId}`,
        {
          ...values,
          scheme_id: scheme,
          syllabus:pdf
        }
      );
      alert.success("Semester Updated Successfully");
      setValues({ ...values, loading: false });
      history.push(`/admin/all-semesters/${values.course_id}`);
    } catch (error) {
      alert.error(error.response.data);
      setValues({ ...values, loading: false });
    }
  };

  return (
    <div>
      <MetaData title={"Update Semester"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>
        <div className="col-12 col-md-10">
          <div>
            <div className="wrapper my-5">
              <form
                className="shadow-lg"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <h1 className="mb-4">Update Semester</h1>
                <div className="form-group">
                  <label htmlFor="name_field">Semester Name</label>
                  <input
                    type="text"
                    id="name_field"
                    className="form-control"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="description_field">Description</label>
                  <textarea
                    className="form-control"
                    id="description_field"
                    rows="3"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="stream_field">Scheme</label>
                  <select
                    id="stream_field"
                    className="form-control"
                    value={scheme}
                    onChange={(e) => setScheme(e.target.value)}
                    required
                  >
                    {schemes &&
                      schemes.map((scheme) => (
                        <option key={scheme.name} value={scheme.id}>
                          {scheme.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="name_field">Price</label>
                  <input
                    type="number"
                    id="name_field"
                    className="form-control"
                    name="price"
                    value={values.price}
                    onChange={handleChange}
                  />
                </div>{" "}
                <div className="form-group">
                  <label htmlFor="name_field">Offer Price</label>
                  <input
                    type="number"
                    id="name_field"
                    className="form-control"
                    name="offer_price"
                    value={values.offer_price}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name_field">Monthly Price</label>
                  <input
                    type="number"
                    id="name_field"
                    className="form-control"
                    name="monthly_subscription_price"
                    value={values.monthly_subscription_price}
                    onChange={handleChange}
                  />
                </div>


                <div className="form-group">
                  <label>Syllabus</label>

                  {pdf ? (
                    <div>
                      <button
                        type="button"
                        className="btn btn-danger "
                        onClick={handlePdfRemove}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                      <p>{uploadButtonText}</p>
                    </div>
                  ) : (
                    <div className="custom-file">
                      <input
                        type="file"
                        name="pdf"
                        className="custom-file-input"
                        id="customFile"
                        onChange={handlePdf}
                        hidden
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        {uploadButtonText}
                      </label>
                    </div>
                  )}
                </div>
                <button
                  id="login_button"
                  type="submit"
                  className="btn btn-block py-3"
                  disabled={values.loading ? true : false}
                >
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateSemester;
