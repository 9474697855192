import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";

import Loader from "../../../layout/Loader";

import MetaData from "../../../layout/MetaData";

import { useAlert } from "react-alert";

import Sidebar from "../../Sidebar";
import axios from "axios";

const AllAnswers = ({ history, match }) => {
  const alert = useAlert();
  const [answers, setAnswers] = useState([]);
  const [questionDetail, setQuestionDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const questionId = match.params.questionId;

  const optionChangeHandler = async (answerId) => {
    try {
      await axios.put(`/api/admin/change-option/${answerId}`);
      setIsUpdated(!isUpdated);
      alert.success("Option Changed successfully");
    } catch (error) {
      alert.error("Option Updation failed");
    }
  };

  const backToQuestionHandler = async () => {
    if (answers && answers.some((e) => e.answer_status)) {
      history.push(`/admin/questions/${questionDetail.lecture_id}`);
    } else {
      alert.error("Please select the Answer");
    }
  };
  const getAnswers = async () => {
    try {
      const { data } = await axios.get(`/api/admin/quiz-answers/${questionId}`);

      setAnswers(data.quizAnswers);
    } catch (error) {}
  };
  const getAuth = async () => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const { data } = await axios.get("/api/admin/auth-admin", config);
    } catch (error) {
      alert.error(error.response.data);
      history.push("/login");
    }
  };

  const answerDeleteHandler = async (answerId) => {
    if (window.confirm("Are you sure?")) {
      try {
        await axios.delete(`/api/admin/answer/${answerId}`);
        setIsDeleted(!isDeleted);
        alert.success("answer deleted successfully");
      } catch (error) {
        alert.error("answer deletion failed");
      }
    }
  };
  const getQuestionDetail = async () => {
    const { data } = await axios.get(
      `/api/admin/question-detail/${questionId}`
    );

    setQuestionDetail(data.questionDetail);
    setLoading(false);
  };
  useEffect(() => {
    try {
      getAuth();
    } catch (error) {}
  }, []);
  useEffect(() => {
    setLoading(true);
    try {
      getAnswers();
      getQuestionDetail();
    } catch (error) {
      alert.error(error);
    }
  }, [alert, isUpdated, isDeleted]);

  const setAnswer = () => {
    const data = {
      columns: [
        {
          label: "Id",
          field: "id",
          sort: "asc",
        },
        {
          label: "Options",
          field: "options",
          sort: "asc",
        },
        {
          label: "Status",
          field: "status",
          sort: "asc",
        },

        {
          label: "Action",
          field: "actions",
        },
      ],
      rows: [],
    };

    {
      answers &&
        answers.forEach((answer) => {
          data.rows.push({
            options: answer.name,
            id: answer.id,
            status: answer.answer_status ? (
              <button disabled className="btn btn-success py-1 px-2 my-2 ">
                <i className="fa fa-check"></i>
              </button>
            ) : (
              <button
                title="Mark as Answer"
                onClick={() => optionChangeHandler(answer.id)}
                className="btn btn-danger py-1 px-2 my-2 "
              >
                <i className="fa fa-times"></i>
              </button>
            ),
            actions: (
              <div>
                <Link
                  to={`/admin/answer/${answer.id}`}
                  className="btn btn-primary py-1 px-2 "
                >
                  <i className="fa fa-pencil"></i>
                </Link>
                <button
                  title="Delete"
                  className="btn btn-danger py-1 px-2 mx-2"
                  type="button"
                  onClick={() => answerDeleteHandler(answer.id)}
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
            ),
          });
        });
    }

    return data;
  };

  return (
    <div>
      <MetaData title={"Options"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="col-12 col-md-10">
            <div>
              <h2 className="my-2"> Options</h2>
              <h4 className="my-2">
                {questionDetail &&
                  questionDetail.module + "/" + questionDetail.subject}
              </h4>
              <h5 className="my-2">
                {questionDetail &&
                  questionDetail.semester + "/" + questionDetail.course}
              </h5>
              <h6 className="">
                {questionDetail &&
                  questionDetail.university +
                    " - " +
                    questionDetail.state +
                    " (" +
                    questionDetail.stream +
                    ")"}
              </h6>
              <h4 className="my-2">
                {questionDetail && "Question: " + questionDetail.question}
              </h4>
              <Link
                to={`/admin/add-answer/${questionId}`}
                className="btn btn-success py-1 px-2 my-2 "
              >
                Add option
              </Link>
              <button
              
                onClick={() => backToQuestionHandler()}
                className="btn btn-info py-1 px-2 my-2 mx-2"
              >
                Back to Questions
              </button>
              <div>
                <MDBDataTable
                  data={setAnswer()}
                  className="px-3"
                  bordered
                  striped
                  hover
                  // order={['id', 'asc' ]}
                />
              </div>{" "}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllAnswers;
