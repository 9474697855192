import React, { useEffect, useState } from "react";

import MetaData from "../../layout/MetaData";

import { useAlert } from "react-alert";

import Sidebar from "../Sidebar";
import axios from "axios";


const AddMonthlySubscription = ({ history }) => {
  const alert = useAlert();
  const [values, setValues] = useState({
    payment_id: "",
    semester_id:"",
    student_id:"",
    loading: false,
  });

  const getAuth = async () => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const { data } = await axios.get("/api/admin/auth-admin", config);
    } catch (error) {
      alert.error(error.response.data);
      history.push("/login");
    }
  };

  useEffect(() => {
    try {
      getAuth();
    } catch (error) {}
  }, []);
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValues({ ...values, loading: true });
    try {
      const { data } = await axios.post("/api/admin/monthly-subscription", {
        ...values,
      });
      setValues({ ...values, loading: false});
      alert.success("Subscription Added Successfully");
      setValues({
        semester_id: "",
        student_id: "",
        payment_id:""
      });

      history.push("/admin/add-monthly_subscription");
    } catch (error) {
      setValues({ ...values, loading: false});
      alert.error(error.response.data);
    }
  };

  return (
    <div>
      <MetaData title={"Add Monthly Subscription"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>
        <div className="col-12 col-md-10">
          <div>
            <div className="wrapper my-5">
              <form
                className="shadow-lg"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <h1 className="mb-4">New Monthly Subscription</h1>

                <div className="form-group">
                  <label htmlFor="name_field">Student Id</label>
                  <input
                    type="number"
                    id="name_field"
                    className="form-control"
                    name="student_id"
                    value={values.student_id}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name_field">Payment Id (from Razorpay)</label><br/>
                  <input
                    type="text"
                    id="name_field"
                    className="form-control"
                    name="payment_id"
                    value={values.payment_id}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name_field">Semester Id</label>
                  <input
                    type="number"
                    id="name_field"
                    className="form-control"
                    name="semester_id"
                    value={values.semester_id}
                    onChange={handleChange}
                  />
                </div>
               

                <button
                  onClick={handleSubmit}
                  id="login_button"
                  type="submit"
                  className="btn btn-block py-3"
                  disabled={values.loading ? true : false}
                >
                 Add
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMonthlySubscription;
