import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";

import Loader from "../../layout/Loader";

import MetaData from "../../layout/MetaData";

import { useAlert } from "react-alert";

import Sidebar from "../Sidebar";
import axios from "axios";
import Resizer from "react-image-file-resizer";

const AllBanners = ({ history, match }) => {
  const alert = useAlert();
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const getAuth = async () => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const { data } = await axios.get("/api/admin/auth-admin", config);
    } catch (error) {
      alert.error(error.response.data);
      history.push("/login");
    }
  };
  const bannerDeleteHandler = async (bannerId, key) => {
    if (window.confirm("Are you sure?")) {
      try {
        await axios.post("/api/s3/delete-image", { key: key });
        await axios.delete(`/api/admin/banner/${bannerId}`);
        setIsDeleted(!isDeleted);
        alert.success("banner deleted successfully");
        history.push(`/admin/banners`);
      } catch (error) {
        alert.error("banner deletion failed");
      }
    }
  };

  useEffect(() => {
    try {
      getAuth();
    } catch (error) {}
  }, []);
  const getBanners = async () => {
    const { data } = await axios.get(`/api/admin/all-banners`);

    setBanners(data.banners);
  };

  useEffect(() => {
    setLoading(true);
    try {
      getBanners();
      setLoading(false);
    } catch (error) {
      alert.error(error);
      setLoading(false);
    }
  }, [alert, isDeleted]);

  const setBanner = () => {
    const data = {
      columns: [
        {
          label: "Id",
          field: "id",
          sort: "asc",
        },
        {
          label: "Title",
          field: "title",
          sort: "asc",
        },
        {
          label: "Web Url",
          field: "web_url",
          sort: "asc",
        },

        {
          label: "image",
          field: "image",
          sort: "asc",
        },

        {
          label: "Action",
          field: "actions",
        },
      ],
      rows: [],
    };

    {
      banners &&
        banners.forEach((banner) => {
          data.rows.push({
            title: banner.title,
            id: banner.id,
            web_url:banner.web_url,
            image: (
              <img
                src={`https://chaloexam.s3.amazonaws.com/${banner.image}`}
                alt=""
                border="0"
                height="75"
                width="75"
              />
            ),

            actions: (
              <div> <Link
              to={`/admin/update-banner/${banner.id}`}
              className="btn btn-primary py-1 px-2 "
            >
              <i className="fa fa-pencil"></i>
            </Link>
                <button
                  title="Delete"
                  className="btn btn-danger py-1 px-2 mx-2"
                  type="button"
                  onClick={() => bannerDeleteHandler(banner.id, banner.image)}
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
            ),
          });
        });
    }

    return data;
  };

  return (
    <div>
      <MetaData title={"Banners"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="col-12 col-md-10">
            <div>
              <h1 className="my-2">Banners</h1>

              <Link
                to={`/admin/add-banner`}
                className="btn btn-success py-1 px-2 my-2 "
              >
                Add banner
              </Link>

              <div>
                <MDBDataTable
                  data={setBanner()}
                  className="px-3"
                  bordered
                  striped
                  hover
                  paging={false}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllBanners;
