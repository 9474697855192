import React, { useEffect, useState } from "react";

import MetaData from "../../layout/MetaData";

import { types, useAlert } from "react-alert";

import Sidebar from "../Sidebar";
import axios from "axios";
import Resizer from "react-image-file-resizer";

const UpdateLecture = ({ history, match }) => {
  const alert = useAlert();
  const [values, setValues] = useState({
    title: "",
    type: "medium",
    loading: false,
    module_id: "",
  });

  const [uploadButtonText, setUploadButtonText] = useState("Upload Pdf");
  const [pdf, setPdf] = useState("");
  const lectureId = match.params.id;
  const types = ["medium", "easy", "hard"];
  const getAuth = async () => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const { data } = await axios.get("/api/admin/auth-admin", config);
    } catch (error) {
      alert.error(error.response.data);
      history.push("/login");
    }
  };

  useEffect(() => {
    try {
      getAuth();
    } catch (error) {}
  }, []);
  useEffect(() => {
    loadLecture();
  }, []);
  const loadLecture = async () => {
    const { data } = await axios.get(`/api/admin/lecture/${lectureId}`);
    const data1 = data.lecture[0];
    if (data1) {
      setValues(data1);
      setPdf(data1.document);
      setUploadButtonText(data1.document);
    }
  };
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handlePdf = async (e) => {
    let file = e.target.files[0];
    var url = await toBase64(file);

    setUploadButtonText(e.target.files[0].name);
    setValues({ ...values, loading: true });

    try {
      let { data } = await axios.post("/api/s3/upload-pdf", {
        document: url,
      });
      alert.success("document uploaded succesfully");
      setValues({ ...values, loading: false });
      setPdf(data.Key);
    } catch (error) {
      console.log(error);
      setValues({ ...values, loading: false });
    }
  };

  const handlePdfRemove = async () => {
    try {
      setValues({ ...values, loading: true });
      const res = await axios.post("/api/s3/delete-pdf", { key: pdf });
      setPdf("");

      setUploadButtonText("Upload Pdf");
      alert.success("document deleted succefully");
      setValues({ ...values, loading: false });
    } catch (error) {
      console.log(error);
      setValues({ ...values, loading: false });
      alert.error("document deletion failed");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setValues({ ...values, loading: true });
      const { data } = await axios.put(
        `/api/admin/update-lecture/${lectureId}`,
        {
          ...values,
          document: pdf,
        }
      );
      alert.success("Lecture Updated Successfully");
      setValues({ ...values, loading: false });
      history.push(`/admin/all-lectures/${values.module_id}`);
    } catch (error) {
      alert.error(error.response.data);
      setValues({ ...values, loading: false });
    }
  };

  return (
    <div>
      <MetaData title={"Add Lecture"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>
        <div className="col-12 col-md-10">
          <div>
            <div className="wrapper my-5">
              <form
                className="shadow-lg"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <h1 className="mb-4">Update Lecture</h1>

                <div className="form-group">
                  <label htmlFor="name_field">Title</label>
                  <input
                    type="text"
                    id="name_field"
                    className="form-control"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="stream_field">Type</label>
                  <select
                    id="stream_field"
                    className="form-control"
                    value={values.type}
                    name="type"
                    onChange={handleChange}
                    required
                  >
                    {types &&
                      types.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Document</label>

                  {pdf ? (
                    <div>
                      <button
                        type="button"
                        className="btn btn-danger "
                        onClick={handlePdfRemove}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                      <p>{uploadButtonText}</p>
                    </div>
                  ) : (
                    <div className="custom-file">
                      <input
                        type="file"
                        name="pdf"
                        className="custom-file-input"
                        id="customFile"
                        onChange={handlePdf}
                        hidden
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        {uploadButtonText}
                      </label>
                    </div>
                  )}
                </div>

                <button
                  id="login_button"
                  type="submit"
                  className="btn btn-block py-3"
                  disabled={values.loading ? true : false}
                >
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateLecture;
