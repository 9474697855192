import React, { useEffect, useState } from "react";

import MetaData from "../../layout/MetaData";

import { useAlert } from "react-alert";

import Sidebar from "../Sidebar";
import axios from "axios";
import Resizer from "react-image-file-resizer";

const AddSemester = ({ history, match }) => {
  const alert = useAlert();
  const [values, setValues] = useState({
    name: "",
    description: "",
    price: "",
    offer_price: "",
    monthly_subscription_price:"",
    loading: false,
  });
  const [schemes, setSchemes] = useState([]);
  const [uploadButtonText, setUploadButtonText] = useState("Upload Pdf");
  const [pdf, setPdf] = useState("");
  const [scheme, setScheme] = useState("1");
  const courseId = match.params.courseId;
  const getAuth = async () => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const { data } = await axios.get("/api/admin/auth-admin", config);
    } catch (error) {
      alert.error(error.response.data);
      history.push("/login");
    }
  };
  const loadSchemes = async () => {
    const { data } = await axios.get(`/api/admin/all-schemes`);
    const data1 = data.schemes;

    if (data1) setSchemes(data1);
  };

  useEffect(() => {
    try {
      getAuth();
      loadSchemes();
    } catch (error) {}
  }, []);
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handlePdf = async (e) => {
    let file = e.target.files[0];
    var url = await toBase64(file);

    setUploadButtonText(e.target.files[0].name);
    setValues({ ...values, loading: true });

    try {
      let { data } = await axios.post("/api/s3/upload-pdf", {
        document: url,
      });
      alert.success("Syllabus uploaded succesfully");
      setValues({ ...values, loading: false });
      setPdf(data.Key);
    } catch (error) {
      console.log(error);
      setValues({ ...values, loading: false });
    }
  };

  const handlePdfRemove = async () => {
    try {
      setValues({ ...values, loading: true });
      const res = await axios.post("/api/s3/delete-pdf", { key: pdf });
      setPdf("");

      setUploadButtonText("Upload Pdf");
      alert.success("Syllabus deleted succesfully");
      setValues({ ...values, loading: false });
    } catch (error) {
      console.log(error);
      setValues({ ...values, loading: false });
      alert.error("deletion failed");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    //  console.log(universityId);
    try {
      setValues({ ...values, loading: true });
      const { data } = await axios.post("/api/admin/add-semester", {
        ...values,
        syllabus:pdf,
        scheme_id: scheme,
        course_id: courseId,
      });
      alert.success("Semester Added Successfully");
      setValues({ ...values, loading: false });
      history.push(`/admin/all-semesters/${courseId}`);
    } catch (error) {
      alert.error(error.response.data);
      setValues({ ...values, loading: false });
    }
  };

  return (
    <div>
      <MetaData title={"Add Semester"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>
        <div className="col-12 col-md-10">
          <div>
            <div className="wrapper my-5">
              <form
                className="shadow-lg"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <h1 className="mb-4">Add Semester</h1>
                <div className="form-group">
                  <label htmlFor="name_field">Semester Name</label>
                  <input
                    type="text"
                    id="name_field"
                    className="form-control"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="description_field">Description</label>
                  <textarea
                    className="form-control"
                    id="description_field"
                    rows="3"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div className="form-group">
                  <label htmlFor="stream_field">Scheme</label>
                  <select
                    id="stream_field"
                    className="form-control"
                    value={scheme}
                    onChange={(e) => setScheme(e.target.value)}
                    required
                  >
                    {schemes &&
                      schemes.map((scheme) => (
                        <option key={scheme.name} value={scheme.id}>
                          {scheme.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="name_field">Price</label>
                  <input
                    type="number"
                    id="name_field"
                    className="form-control"
                    name="price"
                    value={values.price}
                    onChange={handleChange}
                  />
                </div>{" "}
                <div className="form-group">
                  <label htmlFor="name_field">Offer Price</label>
                  <input
                    type="number"
                    id="name_field"
                    className="form-control"
                    name="offer_price"
                    value={values.offer_price}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name_field">Monthly Price</label>
                  <input
                    type="number"
                    id="name_field"
                    className="form-control"
                    name="monthly_subscription_price"
                    value={values.monthly_subscription_price}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label>Syllabus</label>

                  <div className="custom-file">
                    <input
                      type="file"
                      name="pdf"
                      className="custom-file-input"
                      id="customFile"
                      onChange={handlePdf}
                      hidden
                    />
                    <label className="custom-file-label" htmlFor="customFile">
                      {uploadButtonText}
                    </label>
                  </div>
                  {pdf && (
                    <div>
                      <button
                        type="button"
                        className="btn btn-danger py-1 px-1 mt-1"
                        onClick={handlePdfRemove}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                      <p>{uploadButtonText}</p>
                    </div>
                  )}
                </div>
                <button
                  id="login_button"
                  type="submit"
                  className="btn btn-block py-3"
                  disabled={values.loading ? true : false}
                >
                  CREATE
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSemester;
