import React, { useEffect, useState } from "react";

import MetaData from "../../../layout/MetaData";

import { useAlert } from "react-alert";

import Sidebar from "../../Sidebar";
import axios from "axios";

const AddAnswer = ({ history ,match }) => {
  const alert = useAlert();
  const [values, setValues] = useState({
    option: "",
    loading: false,
  });

const questionId=match.params.questionId
  const getAuth = async () => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const { data } = await axios.get("/api/admin/auth-admin", config);
    } catch (error) {
      alert.error(error.response.data);
      history.push("/login");
    }
  };

  useEffect(() => {
    try {
      getAuth();
    } catch (error) {}
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setValues({ ...values, loading: true });
      const { data } = await axios.post("/api/admin/add-answer", {
        ...values,
        question_id:questionId ,
      });
      setValues({ ...values, loading: false });
      alert.success("Option Added");
      history.push(`/admin/answers/${questionId}`);
    } catch (error) {
      alert.error(error.response.data);
      setValues({ ...values, loading: false });
    }
  };

  return (
    <div>
      <MetaData title={"Add Option"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>
        <div className="col-12 col-md-10">
          <div>
            <div className="wrapper my-5">
              <form
                className="shadow-lg"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <h1 className="mb-4">New Option</h1>

              

                <div className="form-group">
                  <label htmlFor="description_field">Answer</label>
                  <textarea
                    className="form-control"
                    id="description_field"
                    rows="7"
                    cols="5"
                    name="option"
                    value={values.option}
                    onChange={handleChange}
                  ></textarea>
                </div>

              
                <button
                  id="login_button"
                  type="submit"
                  className="btn btn-block py-3"
                  disabled={values.loading ? true : false}
                >
                 Create
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAnswer;
