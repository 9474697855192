import React, { useEffect, useState } from "react";

import MetaData from "../../layout/MetaData";

import { useAlert } from "react-alert";

import Sidebar from "../Sidebar";
import axios from "axios";


const AddState = ({ history }) => {
  const alert = useAlert();
  const [values, setValues] = useState({
    name: "",
    description: "",
    uploading: false,
    loading: false,
  });
  const [streams, setStreams] = useState([]);

  const [stream, setStream] = useState("1");
  const getAuth = async () => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const { data } = await axios.get("/api/admin/auth-admin", config);
    } catch (error) {
      alert.error(error.response.data);
      history.push("/login");
    }
  };

  useEffect(() => {
    try {
      getAuth();
    } catch (error) {}
  }, []);
  useEffect(() => {
    loadStreams();
  }, []);

  const loadStreams = async () => {
    const { data } = await axios.get(`/api/admin/all-streams`);
    const data1 = data.streams;

    if (data1) setStreams(data1);
  };
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log();
    try {
      setValues({ ...values, loading: true });
      const { data } = await axios.post("/api/admin/add-state", {
        ...values,
        stream_id: stream,
      });
      alert.success("State Added Successfully");
      setValues({ ...values, loading: false });
      history.push("/admin/states");
    } catch (error) {
      alert.error(error.response.data);
      setValues({ ...values, loading: false });
    }
  };

  return (
    <div>
      <MetaData title={"Add State"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>
        <div className="col-12 col-md-10">
          <div>
            <div className="wrapper my-5">
              <form
                className="shadow-lg"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <h1 className="mb-4">New State</h1>

                <div className="form-group">
                  <label htmlFor="name_field">Name</label>
                  <input
                    type="text"
                    id="name_field"
                    className="form-control"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="description_field">Description</label>
                  <textarea
                    className="form-control"
                    id="description_field"
                    rows="7"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                  ></textarea>
                </div>

                <div className="form-group">
                  <label htmlFor="stream_field">Streams</label>
                  <select
                    id="stream_field"
                    className="form-control"
                    name="stream"
                    value={stream}
                    onChange={(e) => setStream(e.target.value)}
                    required
                  >
                    {streams &&
                      streams.map((stream) => (
                        <option key={stream.name} value={stream.id}>
                          {stream.name}
                        </option>
                      ))}
                  </select>
                </div>
                <button
                  id="login_button"
                  type="submit"
                  className="btn btn-block py-3"
                  disabled={values.loading ? true : false}
                >
                  CREATE
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddState;
