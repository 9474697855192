import React, { useEffect, useState } from "react";
import Loader from "../../layout/Loader";
import MetaData from "../../layout/MetaData";
import { useAlert } from "react-alert";
import Sidebar from "../Sidebar";
import axios from "axios";

const SendNotification = ({ history, match }) => {
  const alert = useAlert();
  const [universities, setUniversities] = useState([]);
  const [university, setUniversity] = useState('1');

  const [values, setValues] = useState({
    title: "",
    subtitle: "",
    loading: false,
  });

  const getAuth = async () => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const { data } = await axios.get("/api/admin/auth-admin", config);
    } catch (error) {
      alert.error(error.response.data);
      history.push("/login");
    }
  };
  const getUniversities = async (config) => {
    try {
      setValues({ ...values, loading: true });
      const { data } = await axios.get("/api/admin/all-universities",config);
      setUniversities(data.universities);
      setValues({ ...values, loading: false });
    } catch (error) {}
  };
  useEffect(() => {
    try {
      const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
      getAuth();
      getUniversities(config);
    } catch (error) {}
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setValues({ ...values, loading: true });
      const { data } = await axios.post("/api/admin/sendNotification", {
        ...values,
        university_id: university,
      });
      setValues({ ...values, loading: false });
      alert.success("Notification Sent");
      history.push(`/admin/notification`);
      setValues({
        title: "",
        subtitle: "",
      });
    } catch (error) {
      setValues({ ...values, loading: false });
      alert.error(error.response.data);
      history.push(`/admin/notification`);
    }
  };

  return (
    <div>
      <MetaData title={"Send Notification"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>

        {values.loading ? (
          <Loader />
        ) : (
          <div className="col-12 col-md-10">
            <div>
              <div className="wrapper my-5">
                <form
                  className="shadow-lg"
                  onSubmit={handleSubmit}
                  encType="multipart/form-data"
                >
                  <h1 className="mb-4">New Notification</h1>
                  <div className="form-group">
                    <label htmlFor="description_field">Title</label>
                    <input
                      type="text"
                      id="name_field"
                      className="form-control"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="description_field">Subtitle</label>
                    <textarea
                      className="form-control"
                      id="description_field"
                      rows="7"
                      cols="5"
                      name="subtitle"
                      value={values.subtitle}
                      onChange={handleChange}
                    ></textarea>
                  </div>

                  <div className="form-group">
                    <label htmlFor="stream_field">University</label>
                    <select
                      id="state_field"
                      className="form-control"
                      value={university}
                      onChange={(e) => setUniversity(e.target.value)}
                      required
                    >
                      {universities &&
                        universities.map((university) => (
                          <option key={university.id} value={university.id}>
                            {university.university_name +
                              " - " +
                              university.state_name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <button
                    id="login_button"
                    type="submit"
                    className="btn btn-block py-3"
                    disabled={values.loading ? true : false}
                  >
                    Send
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SendNotification;
