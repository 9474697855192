import React, { useEffect, useState } from "react";

import MetaData from "../../layout/MetaData";

import { useAlert } from "react-alert";

import Sidebar from "../Sidebar";
import axios from "axios";


const AddDemoCourse = ({ history }) => {
  const alert = useAlert();
  const [values, setValues] = useState({
    purpose: "",
    course_id:"",
    referred_by:"",
    student_id:"",
    duration:0,
    loading: false,
  });

  const getAuth = async () => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const { data } = await axios.get("/api/admin/auth-admin", config);
    } catch (error) {
      alert.error(error.response.data);
      history.push("/login");
    }
  };

  useEffect(() => {
    try {
      getAuth();
    } catch (error) {}
  }, []);
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setValues({ ...values, loading: true });
    try {
        const token = localStorage.getItem("token");
        const jwtToken = JSON.parse(token);
        const config = {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        };
      const { data } = await axios.post("/api/admin/demo-course",{
        ...values,duration:parseInt(values.duration)
      }, config);
      setValues({ ...values, loading: false});
      alert.success("Demo Added Successfully");
      setValues({
        course_id: "",
        purpose: "",
        referred_by:"",
        student_id: "",
        duration:0
      });

      history.push("/admin/demo-course");
    } catch (error) {
      setValues({ ...values, loading: false});
      alert.error(error.response.data);
    }
  };

  return (
    <div>
      <MetaData title={"Add Course Demo"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>
        <div className="col-12 col-md-10">
          <div>
            <div className="wrapper my-5">
              <form
                className="shadow-lg"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <h1 className="mb-4">Add Course Demo</h1>

                <div className="form-group">
                  <label htmlFor="name_field">Student Id</label>
                  <input
                    type="number"
                    id="name_field"
                    className="form-control"
                    name="student_id"
                    value={values.student_id}
                    onChange={handleChange}
                  />
                </div>
               
                <div className="form-group">
                  <label htmlFor="name_field">Course Id</label>
                  <input
                    type="number"
                    id="name_field"
                    className="form-control"
                    name="course_id"
                    value={values.course_id}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name_field">Purpose</label><br/>
                
                  <input
                    type="text"
                    id="name_field"
                    className="form-control"
                    name="purpose"
                    value={values.purpose}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name_field">Referred by</label>
                  <input
                    type="text"
                    id="name_field"
                    className="form-control"
                    name="referred_by"
                    value={values.referred_by}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name_field">Duration</label>
                  <label htmlFor="name_field" style={{fontSize:15,fontWeight:"normal"}}>(make duration '0' for demo without expiry)</label>
                  <input
                    type="number"
                    id="name_field"
                    className="form-control"
                    name="duration"
                    value={values.duration}
                    onChange={handleChange}
                  />
                </div>

                <button
                  onClick={handleSubmit}
                  id="login_button"
                  type="submit"
                  className="btn btn-block py-3"
                  disabled={values.loading ? true : false}
                >
                 Add
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDemoCourse;
