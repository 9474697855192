import React from "react";

const Footer1 = () => {
  return (
    <div>
      <footer className="py-1">
        <p className="text-center  mt-1">
          Chaloexam - 2022, All Rights Reserved
        </p>
      </footer>
    </div>
  );
};

export default Footer1;
