import React from "react";

import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Footer1 from "./components/layout/Footer1";
import Header from "./components/layout/Header";

//admin Imports

import AddStream from "./components/admin/stream/AddStream";
import AllStreams from "./components/admin/stream/AllStreams";
import UpdateStream from "./components/admin/stream/UpdateStream";
import AddState from "./components/admin/state/AddState";
import AllStates from "./components/admin/state/AllStates";
import UpdateState from "./components/admin/state/UpdateState";
import AddUniversity from "./components/admin/university/AddUniversity";
import UpdateUniversity from "./components/admin/university/UpdateUniversity";
import AllUniversities from "./components/admin/university/AllUniversities";
import AllCourses from "./components/admin/course/AllCourses";
import AddCourse from "./components/admin/course/AddCourse";
import UpdateCourse from "./components/admin/course/UpdateCourse";
import AllSemesters from "./components/admin/semester/AllSemesters";
import AddSemester from "./components/admin/semester/AddSemester";
import UpdateSemester from "./components/admin/semester/UpdateSemester";
import AllSubjects from "./components/admin/subject/AllSubjects";
import AddSubject from "./components/admin/subject/AddSubject";
import UpdateSubject from "./components/admin/subject/UpdateSubject";
import AllModules from "./components/admin/module/AllModules";
import AddModule from "./components/admin/module/AddModule";
import UpdateModule from "./components/admin/module/UpdateModule";
import AllLectures from "./components/admin/lecture/AllLectures";
import AddLecture from "./components/admin/lecture/AddLecture";
import UpdateLecture from "./components/admin/lecture/UpdateLecture";
import AddAdmin from "./components/admin/AddAdmin";
import AdminLogin from "./components/admin/AdminLogin";
import AllQuestions from "./components/admin/quiz/question/AllQuestions";
import AddQuestion from "./components/admin/quiz/question/AddQuestion";
import UpdateQuestion from "./components/admin/quiz/question/UpdateQuestion";
import AllAnswers from "./components/admin/quiz/answer/AllAnswers";
import AddAnswer from "./components/admin/quiz/answer/AddAnswer";
import UpdateAnswer from "./components/admin/quiz/answer/UpdateAnswer";
import AllStudents from "./components/admin/student/AllStudents";
import AllSchemes from "./components/admin/scheme/AllSchemes";
import AddScheme from "./components/admin/scheme/AddScheme";
import UpdateScheme from "./components/admin/scheme/UpdateScheme";
import AddSubscription from "./components/admin/student/AddSubscription";
import RemoveSubscription from "./components/admin/student/RemoveSubscription";
import AllBanners from "./components/admin/Banner/AllBanner";
import AddBanner from "./components/admin/Banner/AddBanner";
import AllReviewVideos from "./components/admin/reviewVideos/AllReviewVideos";
import AddReviewVideo from "./components/admin/reviewVideos/AddReviewVideo";
import AllCourseUnlock from "./components/admin/student/AllCourseUnlock";
import SendNotification from "./components/admin/Notification/SendNotification";
import AddMonthlySubscription from "./components/admin/student/AddMonthlySubscription";
import UpdateBanner from "./components/admin/Banner/UpdateBanner";
import AddDemo from "./components/admin/student/AddDemo";
import DemoList from "./components/admin/student/DemoList";
import AddDemoCourse from "./components/admin/student/AddDemoCourse";
import DemoCourseList from "./components/admin/student/DemoCourseList";
import RemoveDemoCourse from "./components/admin/student/RemoveDemoCourse";
import AddFullAppDemo from "./components/admin/student/AddFullAppDemo";
import RemoveFullDemo from "./components/admin/student/RemoveFullDemo";
import FullDemoList from "./components/admin/student/FullDemoList";

function App() {
  return (
    <Router>
      <Header />
      <Route path="/login" isAdmin={true} component={AdminLogin} exact />
      <Route path="/" isAdmin={true} component={AllUniversities} exact />
      <Route
        path="/admin/streams"
        isAdmin={true}
        component={AllStreams}
        exact
      />
      <Route
        path="/admin/add-stream"
        isAdmin={true}
        component={AddStream}
        exact
      />
      <Route
        path="/admin/stream/:id"
        isAdmin={true}
        component={UpdateStream}
        exact
      />
      <Route
        path="/admin/add-state"
        isAdmin={true}
        component={AddState}
        exact
      />
      <Route path="/admin/states" isAdmin={true} component={AllStates} exact />
      <Route
        path="/admin/state/:id"
        isAdmin={true}
        component={UpdateState}
        exact
      />
      <Route
        path="/admin/add-university"
        isAdmin={true}
        component={AddUniversity}
        exact
      />
      <Route
        path="/admin/university/:id"
        isAdmin={true}
        component={UpdateUniversity}
        exact
      />
      <Route
        path="/admin/courses/:id"
        isAdmin={true}
        component={AllCourses}
        exact
      />
      <Route
        path="/admin/add-course/:universityId"
        isAdmin={true}
        component={AddCourse}
        exact
      />
      <Route
        path="/admin/course/:id"
        isAdmin={true}
        component={UpdateCourse}
        exact
      />
      <Route
        path="/admin/all-semesters/:courseId"
        isAdmin={true}
        component={AllSemesters}
        exact
      />
      <Route
        path="/admin/add-semester/:courseId"
        isAdmin={true}
        component={AddSemester}
        exact
      />
      <Route
        path="/admin/semester/:id"
        isAdmin={true}
        component={UpdateSemester}
        exact
      />
      <Route
        path="/admin/all-subjects/:semesterId"
        isAdmin={true}
        component={AllSubjects}
        exact
      />
      <Route
        path="/admin/add-subject/:semesterId"
        isAdmin={true}
        component={AddSubject}
        exact
      />
      <Route
        path="/admin/subject/:id"
        isAdmin={true}
        component={UpdateSubject}
        exact
      />
      <Route
        path="/admin/all-modules/:subjectId"
        isAdmin={true}
        component={AllModules}
        exact
      />
      <Route
        path="/admin/add-module/:subjectId"
        isAdmin={true}
        component={AddModule}
        exact
      />
      <Route
        path="/admin/module/:id"
        isAdmin={true}
        component={UpdateModule}
        exact
      />
      <Route
        path="/admin/all-lectures/:moduleId"
        isAdmin={true}
        component={AllLectures}
        exact
      />
      <Route
        path="/admin/add-lecture/:moduleId"
        isAdmin={true}
        component={AddLecture}
        exact
      />
      <Route
        path="/admin/lecture/:id"
        isAdmin={true}
        component={UpdateLecture}
        exact
      />{" "}
      <Route
        path="/admin/questions/:lectureId"
        isAdmin={true}
        component={AllQuestions}
        exact
      />
      <Route
        path="/admin/add-question/:lectureId"
        isAdmin={true}
        component={AddQuestion}
        exact
      />
      <Route
        path="/admin/question/:id"
        isAdmin={true}
        component={UpdateQuestion}
        exact
      />
      <Route
        path="/admin/answers/:questionId"
        isAdmin={true}
        component={AllAnswers}
        exact
      />
      <Route
        path="/admin/add-answer/:questionId"
        isAdmin={true}
        component={AddAnswer}
        exact
      />
      <Route
        path="/admin/answer/:id"
        isAdmin={true}
        component={UpdateAnswer}
        exact
      />
      <Route
        path="/admin/all-students"
        isAdmin={true}
        component={AllStudents}
        exact
      />
       <Route
        path="/admin/demo-students"
        isAdmin={true}
        component={DemoList}
        exact
      />
        <Route
        path="/admin/demo-course"
        isAdmin={true}
        component={AddDemoCourse}
        exact
      />
         <Route
        path="/admin/demo-courseStudents"
        isAdmin={true}
        component={DemoCourseList}
        exact
      />
         <Route
        path="/admin/fulldemo-students"
        isAdmin={true}
        component={FullDemoList}
        exact
      />
         <Route
        path="/admin/remove-democourse"
        isAdmin={true}
        component={RemoveDemoCourse}
        exact
      />
           <Route
        path="/admin/add-fulldemo"
        isAdmin={true}
        component={AddFullAppDemo}
        exact
      />
             <Route
        path="/admin/remove-fulldemo"
        isAdmin={true}
        component={RemoveFullDemo}
        exact
      />
      <Route
        path="/admin/all-schemes"
        isAdmin={true}
        component={AllSchemes}
        exact
      />
      <Route
        path="/admin/add-scheme"
        isAdmin={true}
        component={AddScheme}
        exact
      />
      <Route
        path="/admin/scheme/:id"
        isAdmin={true}
        component={UpdateScheme}
        exact
      />
      <Route
        path="/admin/add-subscription"
        isAdmin={true}
        component={AddSubscription}
        exact
      />
         <Route
        path="/admin/add-monthly_subscription"
        isAdmin={true}
        component={AddMonthlySubscription}
        exact
      />
          <Route
        path="/admin/add-appdemo"
        isAdmin={true}
        component={AddDemo}
        exact
      />
      <Route
        path="/admin/remove-subscription"
        isAdmin={true}
        component={RemoveSubscription}
        exact
      />
      <Route
        path="/admin/unlock-AllCourse"
        isAdmin={true}
        component={AllCourseUnlock}
        exact
      />
      <Route
        path="/admin/banners"
        isAdmin={true}
        component={AllBanners}
        exact
      />
      <Route
        path="/admin/add-banner"
        isAdmin={true}
        component={AddBanner}
        exact
      />
        <Route
        path="/admin/update-banner/:id"
        isAdmin={true}
        component={UpdateBanner}
        exact
      />
      <Route
        path="/admin/review-videos"
        isAdmin={true}
        component={AllReviewVideos}
        exact
      />
      <Route
        path="/admin/add-reviewVideo"
        isAdmin={true}
        component={AddReviewVideo}
        exact
      />
      <Route
        path="/admin/notification"
        isAdmin={true}
        component={SendNotification}
        exact
      />
      <Route path="/add-admin/" isAdmin={true} component={AddAdmin} exact />
      <Footer1 />
    </Router>
  );
}

export default App;
