import React, { useEffect, useState } from "react";

import MetaData from "../../layout/MetaData";

import { useAlert } from "react-alert";

import Sidebar from "../Sidebar";
import axios from "axios";
import Resizer from "react-image-file-resizer";

const UpdateModule = ({ history, match }) => {
  const alert = useAlert();
  const [values, setValues] = useState({
    title: "",
    description: "",
    type: "m",
    subject_id: "",
    loading: false,
  });

  const [preview, setPreview] = useState("");
  const [uploadButtonText, setUploadButtonText] = useState("Upload Image");
  const [image, setImage] = useState("");
  const moduleId = match.params.id;
  const getAuth = async () => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const { data } = await axios.get("/api/admin/auth-admin", config);
    } catch (error) {
      alert.error(error.response.data);
      history.push("/login");
    }
  };

  useEffect(() => {
    try {
      getAuth();
    } catch (error) {}
  }, []);
  const types = ["m", "n"];
  useEffect(() => {
    loadModule();
  }, []);
  const loadModule = async () => {
    const { data } = await axios.get(`/api/admin/module/${moduleId}`);
    const data1 = data.module[0];
    if (data1) {
      setValues(data1);
      setImage(data1.image);
    }
  };
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleImage = (e) => {
    let file = e.target.files[0];
    setPreview(window.URL.createObjectURL(e.target.files[0]));
    setUploadButtonText(e.target.files[0].name);
    setValues({ ...values, loading: true });
    Resizer.imageFileResizer(file, 720, 500, "JPEG", 100, 0, async (uri) => {
      try {
        let { data } = await axios.post("/api/s3/upload-image", {
          image: uri,
        });
        console.log("image uploaded", data);
        setValues({ ...values, loading: false });
        setImage(data.key);
      } catch (error) {
        console.log(error);
        setValues({ ...values, loading: false });
      }
    });
  };

  const handleImageRemove = async () => {
    try {
      setValues({ ...values, loading: true });
      const res = await axios.post("/api/s3/delete-image", { key: image });
      setImage("");
      setPreview("");
      setUploadButtonText("Upload Image");
      alert.success("image deleted succefully");
      setValues({ ...values, loading: false });
    } catch (error) {
      console.log(error);
      setValues({ ...values, loading: false });
      alert.error("Image deletion failed");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //  console.log(universityId);
    try {
      setValues({ ...values, loading: true });
      const { data } = await axios.put(`/api/admin/update-module/${moduleId}`, {
        ...values,
        image: image,
      });
      alert.success("Module Updated Successfully");
      setValues({ ...values, loading: false });
      history.push(`/admin/all-modules/${values.subject_id}`);
    } catch (error) {
      alert.error(error.response.data);
      setValues({ ...values, loading: false });
    }
  };

  return (
    <div>
      <MetaData title={"Update Module"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>
        <div className="col-12 col-md-10">
          <div>
            <div className="wrapper my-5">
              <form
                className="shadow-lg"
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                <h1 className="mb-4">Update Module</h1>

                <div className="form-group">
                  <label htmlFor="name_field">Title</label>
                  <input
                    type="text"
                    id="name_field"
                    className="form-control"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="description_field">Description</label>
                  <textarea
                    className="form-control"
                    id="description_field"
                    rows="3"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                  ></textarea>
                </div>

                <div className="form-group">
                  <label htmlFor="stream_field">Type</label>
                  <select
                    id="stream_field"
                    className="form-control"
                    value={values.type}
                    name="type"
                    onChange={handleChange}
                    required
                  >
                    {types &&
                      types.map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group">
                  <label>Image</label>

                  <div className="custom-file">
                    <input
                      type="file"
                      name="image"
                      className="custom-file-input"
                      id="customFile"
                      onChange={handleImage}
                      hidden
                    />
                    <label className="custom-file-label" htmlFor="customFile">
                      {uploadButtonText}
                    </label>
                  </div>

                  {preview && (
                    <div>
                      <img
                        src={preview}
                        key={preview}
                        alt="Images Preview"
                        className="mt-3 mr-2"
                        width="55"
                        height="52"
                      />
                      <button
                        type="button"
                        className="btn btn-danger py-1 px-1 mt-1"
                        onClick={handleImageRemove}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </div>
                  )}
                </div>

                <button
                  id="login_button"
                  type="submit"
                  className="btn btn-block py-3"
                  disabled={values.loading ? true : false}
                >
                  Update
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateModule;
