import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";

import Loader from "../../layout/Loader";

import MetaData from "../../layout/MetaData";

import { useAlert } from "react-alert";

import Sidebar from "../Sidebar";
import axios from "axios";
import Resizer from "react-image-file-resizer";

const AllStreams = ({ history }) => {
  const alert = useAlert();
  const [streams, setStreams] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const getStreams = async () => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const { data } = await axios.get("/api/admin/all-streams", config);
      console.log(data.streams);
      setStreams(data.streams);
      setLoading(false);
    } catch (error) {
      history.push("/login");
    }
  };
  const hideHandler = async (streamId) => {
    try {
      await axios.put(`/api/admin/stream-hide/${streamId}`);
      setIsHide(!isHide);
      alert.success("stream status updated successfully");
    } catch (error) {
      alert.error("failed");
    }
  };
  const getAuth = async () => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const { data } = await axios.get("/api/admin/auth-admin", config);
    } catch (error) {
      alert.error(error.response.data);
      history.push("/login");
    }
  };

  useEffect(() => {
    try {
      getAuth();
    } catch (error) {}
  }, []);
  useEffect(() => {
    setLoading(true);
    try {
      getStreams();
    } catch (error) {
      alert.error(error);
    }
  }, [alert, isHide]);

  const setStream = () => {
    const data = {
      columns: [
        {
          label: "Stream Name",
          field: "name",
          sort: "asc",
        },
        {
          label: "Stream Id",
          field: "id",
          sort: "asc",
        },
        {
          label: "Description",
          field: "description",
          sort: "asc",
        },
        {
          label: "image",
          field: "image",
          sort: "asc",
        },
        {
          label: "Published",
          field: "published",
          sort: "asc",
        },
        {
          label: "Action",
          field: "actions",
        },
      ],
      rows: [],
    };

    {
      streams &&
        streams.forEach((stream) => {
          data.rows.push({
            name: stream.name,
            id: stream.id,
            description: stream.description,
            image: (
              <img
                src={`https://chaloexam.s3.amazonaws.com/${stream.path}`}
                alt=""
                border="3"
                height="100"
                width="100"
              />
            ),
            published: stream.published ? (
              <button
                className="btn btn-success py-1 px-2 my-2 "
                onClick={() => hideHandler(stream.id)}
              >
                <i className="fa fa-check"></i>
              </button>
            ) : (
              <button
                onClick={() => hideHandler(stream.id)}
                className="btn btn-danger py-1 px-2 my-2 "
              >
                <i className="fa fa-times"></i>
              </button>
            ),
            actions: (
              <div>
                <Link
                  to={`/admin/stream/${stream.id}`}
                  className="btn btn-primary py-1 px-2 "
                >
                  <i className="fa fa-pencil"></i>
                </Link>
              </div>
            ),
          });
        });
    }

    return data;
  };

  return (
    <div>
      <MetaData title={"All Streams"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>
        <div className="col-12 col-md-10">
          <div>
            <h1 className="my-5"> All Streams</h1>
            {loading ? (
              <Loader />
            ) : (
              <div>
                <MDBDataTable
                  data={setStream()}
                  className="px-3"
                  bordered
                  striped
                  hover
                  paging={false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllStreams;
