import React, { useState, forwardRef, useEffect } from "react";

import axios from "axios";
import MaterialTable from "material-table";
import Loader from "../../layout/Loader";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import MetaData from "../../layout/MetaData";

import Sidebar from "../Sidebar";
const AllStudents = () => {
  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState([]);

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const columns = [
    { title: "Id", field: "id" },
    { title: "Name", field: "name" },
    { title: "Phone", field: "phone" },
    { title: "Email", field: "email" },
    { title: "Created", field: "created" },
  ];
  const getStudents = async () => {
    setLoading(true);
    const { data } = await axios.get("/api/admin/all-students");
    setStudents(data.students);
    setLoading(false);
  };

  useEffect(() => {
    getStudents();
  }, []);
  return (
    <div>
      <MetaData title={"All Students"} />

      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>
        <div className="col-12 col-md-10">
          <div>
            {loading ? (
              <Loader />
            ) : (
              <div>
                <MaterialTable
                  data={students}
                  icons={tableIcons}
                  columns={columns}
                  title={"All Students"}
                  options={{
                    filtering: true,
                    pageSize: 20,
                    pageSizeOptions: [10, 20, 30, 40, 50],
                    exportButton: true,
                    exportAllData: true,
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AllStudents;
