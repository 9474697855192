import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";

import Loader from "../../../layout/Loader";

import MetaData from "../../../layout/MetaData";

import { useAlert } from "react-alert";

import Sidebar from "../../Sidebar";
import axios from "axios";

const AllQuestions = ({ history, match }) => {
  const alert = useAlert();
  const [questions, setQuestions] = useState([]);
  const [quizDetail, setQuizDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const lectureId = match.params.lectureId;
  const getQuestions = async () => {
    try {
      const { data } = await axios.get(
        `/api/admin/quiz-questions/${lectureId}`
      );

      setQuestions(data.quizQuestions);
    } catch (error) {}
  };
  const getAuth = async () => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const { data } = await axios.get("/api/admin/auth-admin", config);
    } catch (error) {
      alert.error(error.response.data);
      history.push("/login");
    }
  };
  const questionDeleteHandler = async (questionId) => {
    if (window.confirm("Are you sure?")) {
      try {
        await axios.delete(`/api/admin/question/${questionId}`);
        setIsDeleted(!isDeleted);
        alert.success("question deleted successfully");
      } catch (error) {
        alert.error("question deletion failed");
      }
    }
  };
  const getQuizDetail = async () => {
    const { data } = await axios.get(`/api/admin/quiz-detail/${lectureId}`);

    setQuizDetail(data.quizDetail);
    setLoading(false);
  };
  useEffect(() => {
    try {
      getAuth();
    } catch (error) {}
  }, []);
  useEffect(() => {
    setLoading(true);
    try {
      getQuestions();
      getQuizDetail();
    } catch (error) {
      alert.error(error);
    }
  }, [alert, isDeleted]);

  const setQuestion = () => {
    const data = {
      columns: [
        {
          label: "Id",
          field: "id",
          sort: "asc",
        },
        {
          label: "Question",
          field: "question",
          sort: "asc",
        },

        {
          label: "Action",
          field: "actions",
        },
      ],
      rows: [],
    };

    {
      questions &&
        questions.forEach((question) => {
          data.rows.push({
            question: question.question,
            id: (
              <div>
                <Link to={`/admin/answers/${question.id}`}>{question.id}</Link>
              </div>
            ),
            actions: (
              <div>
                <Link
                  title="Edit"
                  to={`/admin/question/${question.id}`}
                  className="btn btn-primary py-1 px-2 "
                >
                  <i className="fa fa-pencil"></i>
                </Link>
                <button
                  title="Delete"
                  className="btn btn-danger py-1 px-2 my-1"
                  type="button"
                  onClick={() => questionDeleteHandler(question.id)}
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
            ),
          });
        });
    }

    return data;
  };

  return (
    <div>
      <MetaData title={"Questions"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="col-12 col-md-10">
            <div>
              <h2 className="my-2"> Questions</h2>
              <h4 className="my-2">
                {quizDetail && quizDetail.module + "/" + quizDetail.subject}
              </h4>
              <h5 className="my-2">
                {quizDetail && quizDetail.semester + "/" + quizDetail.course}
              </h5>
              <h6 className="">
                {quizDetail &&
                  quizDetail.university +
                    " - " +
                    quizDetail.state +
                    " (" +
                    quizDetail.stream +
                    ")"}
              </h6>
              <Link
                to={`/admin/add-question/${lectureId}`}
                className="btn btn-success py-1 px-2 my-2 "
              >
                Add Question
              </Link>
              <Link
                to={`/admin/all-lectures/${quizDetail.module_id}`}
                className="btn btn-info py-1 px-2 my-2 mx-2"
              >
                Back to Lectures
              </Link>
              <div>
                <MDBDataTable
                  data={setQuestion()}
                  className="px-3"
                  bordered
                  striped
                  hover
                  entries={20}
                  // entriesOptions={[20, 30, 40, 60]}
                />
              </div>{" "}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllQuestions;
