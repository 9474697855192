import React from "react";
import { Link } from "react-router-dom";


import "../../App.css";

const Header = ({ }) => {


  return (
    <div>
      <nav className="navbar row">
        <div className="col-12 col-md-3">
          <div className="navbar-brand">
            <Link to="/">
              {/* <img src="/images/logo.png" /> */}
              <p className="title mt-3">
                Chalo<span className="title1">exam</span>
              </p>
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
