import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";

import Loader from "../../layout/Loader";

import MetaData from "../../layout/MetaData";

import { useAlert } from "react-alert";

import Sidebar from "../Sidebar";
import axios from "axios";
import Resizer from "react-image-file-resizer";

const AllStates = ({history}) => {
  const alert = useAlert();
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const getStates = async () => {
    const { data } = await axios.get("/api/admin/all-states");
    console.log(data.states);
    setStates(data.states);
    setLoading(false);
  };
  const getAuth = async () => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const { data } = await axios.get("/api/admin/auth-admin", config);
    } catch (error) {
      alert.error(error.response.data);
      history.push("/login");
    }
  };
  const hideHandler = async (stateId) => {
    try {
      await axios.put(`/api/admin/state-hide/${stateId}`);
      setIsHide(!isHide);
      alert.success("state status updated successfully");
    } catch (error) {
      alert.error("failed");
    }
  };
  useEffect(() => {
    try {
      getAuth();
    } catch (error) {}
  }, []);
  useEffect(() => {
    setLoading(true);
    try {
      getStates();
    } catch (error) {
      alert.error(error);
    }
  }, [alert,isHide]);

  const setState = () => {
    const data = {
      columns: [
        {
          label: "State Id",
          field: "id",
          sort: "asc",
        },
        {
          label: "State Name",
          field: "name",
          sort: "asc",
        },

        {
          label: "Stream Name",
          field: "stream",
          sort: "asc",
        },

        {
          label: "Description",
          field: "description",
          sort: "asc",
        },
        {
          label: "Published",
          field: "published",
          sort: "asc",
        },
        {
          label: "Action",
          field: "actions",
        },
      ],
      rows: [],
    };

    {
      states &&
        states.forEach((state) => {
          data.rows.push({
            name: state.name,
            id: state.id,
            stream: state.stream_name,
            description: state.description,
            published: state.published ? (
              <button
                className="btn btn-success py-1 px-2 my-2 "
                onClick={() => hideHandler(state.id)}
              >
                <i className="fa fa-check"></i>
              </button>
            ) : (
              <button
                onClick={() => hideHandler(state.id)}
                className="btn btn-danger py-1 px-2 my-2 "
              >
                <i className="fa fa-times"></i>
              </button>
            ),
            actions: (
              <div>
                <Link
                  to={`/admin/state/${state.id}`}
                  className="btn btn-primary py-1 px-2 "
                >
                  <i className="fa fa-pencil"></i>
                </Link>
              </div>
            ),
          });
        });
    }

    return data;
  };

  return (
    <div>
      <MetaData title={"All States"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>
        <div className="col-12 col-md-10">
          <div>
            <h1 className="my-5"> All States</h1>
            {loading ? (
              <Loader />
            ) : (
              <div>
                <MDBDataTable
                  data={setState()}
                  className="px-3"
                  bordered
                  striped
                  hover
                  paging={false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllStates;
