import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useAlert } from "react-alert";
import axios from "axios";
const Sidebar = ({ history }) => {
  const alert = useAlert();

  const logouthandler = () => {
    localStorage.removeItem("token");
    alert.success("logged out successfully");
  };
  return (
    <div>
      <div className="sidebar-wrapper ">
        <nav id="sidebar">
          <a
            href="/login"
            className="btn ml-4"
            id="login_btn"
            onClick={logouthandler}
          >
            Logout
          </a>
          <ul className="list-unstyled components mt-3">
            <li>
              <a
                href="#streams"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <i className="fa fa-graduation-cap"></i> Streams
              </a>
              <ul className="collapse list-unstyled" id="streams">
                <li>
                  <Link to="/admin/streams">
                    <i className="fa fa-clipboard"></i> All
                  </Link>
                </li>

                <li>
                  <Link to="/admin/add-stream">
                    <i className="fa fa-plus"></i> Create
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <a
                href="#states"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <i className="fa fa-map-marker"></i> States
              </a>
              <ul className="collapse list-unstyled" id="states">
                <li>
                  <Link to="/admin/states">
                    <i className="fa fa-clipboard"></i> All
                  </Link>
                </li>

                <li>
                  <Link to="/admin/add-state">
                    <i className="fa fa-plus"></i> Create
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <a
                href="#university"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <i className="fa fa-university"></i>University
              </a>
              <ul className="collapse list-unstyled" id="university">
                <li>
                  <Link to="/">
                    <i className="fa fa-clipboard"></i> All
                  </Link>
                </li>

                <li>
                  <Link to="/admin/add-university">
                    <i className="fa fa-plus"></i> Create
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <a
                href="#schemes"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <i className="fa fa-bookmark"></i>Schemes
              </a>
              <ul className="collapse list-unstyled" id="schemes">
                <li>
                  <Link to="/admin/all-schemes">
                    <i className="fa fa-clipboard"></i> All
                  </Link>
                </li>

                <li>
                  <Link to="/admin/add-scheme">
                    <i className="fa fa-plus"></i> Create
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <a
                href="#students"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <i className="fa fa-users"></i> Students
              </a>
              <ul className="collapse list-unstyled" id="students">
                <li>
                  <Link to="/admin/all-students">
                    <i className="fa fa-clipboard"></i> All
                  </Link>
                </li>
              </ul>
              <ul className="collapse list-unstyled" id="students">
                <li>
                  <Link to="/admin/demo-courseStudents">
                    <i className="fa fa-clipboard"></i> Course Demo Students
                  </Link>
                </li>
              </ul>
              <ul className="collapse list-unstyled" id="students">
                <li>
                  <Link to="/admin/fulldemo-students">
                    <i className="fa fa-clipboard"></i> Full Demo Students
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <a
                href="#subscription"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <i className="fa fa-folder-open"></i> Paid Sub
              </a>
              <ul className="collapse list-unstyled" id="subscription">
                <li>
                  <Link to="/admin/add-subscription">
                    <i className="fa fa-plus"></i> Add
                  </Link>
                </li>
                <li>
                  <Link to="/admin/add-monthly_subscription">
                    <i className="fa fa-plus"></i> Add Monthly
                  </Link>
                </li>
                <li>
                  <Link to="/admin/remove-subscription">
                    <i className="fa fa-minus"></i> Remove
                  </Link>
                </li>
                
              </ul>
            </li>


            <li>
              <a
                href="#demosubs"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <i className="fa fa-folder-open"></i> Demo Sub
              </a>
              <ul className="collapse list-unstyled" id="demosubs">
                <li>
                  <Link to="/admin/demo-course">
                    <i className="fa fa-plus"></i> Add Demo Course
                  </Link>
                </li>
              
                <li>
                  <Link to="/admin/remove-democourse">
                    <i className="fa fa-minus"></i> Remove Demo Course
                  </Link>
                </li>
                <li>
                  <Link to="/admin/add-fulldemo">
                    <i className="fa fa-plus"></i> Add Full App Demo
                  </Link>
                </li>
                <li>
                  <Link to="/admin/remove-fulldemo">
                    <i className="fa fa-minus"></i> Remove Full App Demo
                  </Link>
                </li>
              </ul>
            </li>


            <li>
              <a
                href="#banners"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <i className="fa fa-folder-open"></i> Banners
              </a>
              <ul className="collapse list-unstyled" id="banners">
                <li>
                  <Link to="/admin/banners">
                    <i className="fa fa-clipboard"></i> All
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <a
                href="#reviewVideos"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <i className="fa fa-folder-open"></i> Review Videos
              </a>
              <ul className="collapse list-unstyled" id="reviewVideos">
                <li>
                  <Link to="/admin/review-videos">
                    <i className="fa fa-clipboard"></i> All
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <a
                href="#notification"
                data-toggle="collapse"
                aria-expanded="false"
                className="dropdown-toggle"
              >
                <i className="fa fa-bell"></i>Notification
              </a>
              <ul className="collapse list-unstyled" id="notification">
                <li>
                  <Link to="/admin/notification">
                    <i className="fa fa-paper-plane"></i> Send
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
