import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";

import Loader from "../../layout/Loader";

import MetaData from "../../layout/MetaData";

import { useAlert } from "react-alert";

import Sidebar from "../Sidebar";
import axios from "axios";
import Resizer from "react-image-file-resizer";

const AllSubjects = ({ history, match }) => {
  const alert = useAlert();
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [semesterDetail, setSemesterDetail] = useState({});
  const [isHide, setIsHide] = useState(false);
  const semesterId = match.params.semesterId;
  const getAuth = async () => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const { data } = await axios.get("/api/admin/auth-admin", config);
    } catch (error) {
      alert.error(error.response.data);
      history.push("/login");
    }
  };
  const hideHandler = async (subjectId) => {
    try {
      await axios.put(`/api/admin/subject-hide/${subjectId}`);
      setIsHide(!isHide);
      alert.success("subject status updated successfully");
    } catch (error) {
      alert.error("failed");
    }
  };
  useEffect(() => {
    try {
      getAuth();
    } catch (error) {}
  }, []);
  const getSubjects = async () => {
    const { data } = await axios.get(`/api/admin/all-subjects/${semesterId}`);

    setSubjects(data.subjects);

  };
  const getSemesterDetail = async () => {
    const { data } = await axios.get(
      `/api/admin/semester-detail/${semesterId}`
    );

    setSemesterDetail(data.semesterDetail);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    try {
      getSubjects();
      getSemesterDetail();
    } catch (error) {
      alert.error(error);
      setLoading(false);
    }
  }, [alert, isHide]);

  const setSubject = () => {
    const data = {
      columns: [
        {
          label: "Id",
          field: "id",
          sort: "asc",
        },
        {
          label: "Subject Name",
          field: "name",
          sort: "asc",
        },

        {
          label: "Description",
          field: "description",
          sort: "asc",
        },
        {
          label: "Image",
          field: "image",
          sort: "asc",
        },
        {
          label: "Published",
          field: "published",
          sort: "asc",
        },
        {
          label: "Action",
          field: "actions",
        },
      ],
      rows: [],
    };

    {
      subjects &&
        subjects.forEach((subject) => {
          data.rows.push({
            name: subject.name,
            id: (
              <div>
                <Link to={`/admin/all-modules/${subject.id}`} className="py-1 ">
                  {subject.id}
                </Link>
              </div>
            ),
            description: subject.description,

            image: (
              <img
                src={`https://chaloexam.s3.amazonaws.com/${subject.image}`}
                alt=""
                border="0"
                height="75"
                width="75"
              />
            ),
            published: subject.published ? (
              <button
                className="btn btn-success py-1 px-2 my-2 "
                onClick={() => hideHandler(subject.id)}
              >
                <i className="fa fa-check"></i>
              </button>
            ) : (
              <button
                onClick={() => hideHandler(subject.id)}
                className="btn btn-danger py-1 px-2 my-2 "
              >
                <i className="fa fa-times"></i>
              </button>
            ),
            actions: (
              <div>
                <Link
                  to={`/admin/subject/${subject.id}`}
                  className="btn btn-primary py-1 px-2 "
                >
                  <i className="fa fa-pencil"></i>
                </Link>
              </div>
            ),
          });
        });
    }

    return data;
  };

  return (
    <div>
      <MetaData title={" Subjects"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="col-12 col-md-10">
            <div>
              <h2 className="my-2">Subjects</h2>
              <h4 className="my-2">{semesterDetail && semesterDetail.name}</h4>
              <h5 className="my-2">
                {semesterDetail && semesterDetail.course}
              </h5>
              <h5 className="">
                {semesterDetail &&
                  semesterDetail.university +
                    " - " +
                    semesterDetail.state +
                    " (" +
                    semesterDetail.stream +
                    ")"}
              </h5>

              <Link
                to={`/admin/add-subject/${semesterId}`}
                className="btn btn-success py-1 px-2 my-2 "
              >
                Add Subject
              </Link>
              <Link
                to={`/admin/all-semesters/${semesterDetail.course_id}`}
                className="btn btn-info py-1 px-2 my-2 mx-2"
              >
                Back to Semesters
              </Link>
              <div>
                <MDBDataTable
                  data={setSubject()}
                  className="py-1"
                  bordered
                  striped
                  hover
                  paging={false}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllSubjects;
