import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";

import Loader from "../../layout/Loader";

import MetaData from "../../layout/MetaData";

import { useAlert } from "react-alert";

import Sidebar from "../Sidebar";
import axios from "axios";
import Resizer from "react-image-file-resizer";

const AllModules = ({ history, match }) => {
  const alert = useAlert();
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subjectDetail, setSubjectDetail] = useState({});
  const [isHide, setIsHide] = useState(false);
  const subjectId = match.params.subjectId;
  const getAuth = async () => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const { data } = await axios.get("/api/admin/auth-admin", config);
    } catch (error) {
      alert.error(error.response.data);
      history.push("/login");
    }
  };
  const hideHandler = async (moduleId) => {
    try {
      await axios.put(`/api/admin/module-hide/${moduleId}`);
      setIsHide(!isHide);
      alert.success("module status updated successfully");
    } catch (error) {
      alert.error("failed");
    }
  };
  const previewHandler = async (moduleId) => {
    try {
      await axios.put(`/api/admin/module-preview/${moduleId}`);
      setIsHide(!isHide);
      alert.success("module preview changed");
    } catch (error) {
      alert.error("failed");
    }
  };

  const positionIncrease = async (moduleId) => {
    try {
      await axios.put(`/api/admin/module-positionIncrease/${moduleId}`);
      setIsHide(!isHide);
    } catch (error) {
      alert.error("failed");
    }
  };

  const positionDecrease = async (moduleId) => {
    try {
      await axios.put(`/api/admin/module-positionDecrease/${moduleId}`);
      setIsHide(!isHide);
    } catch (error) {
      alert.error("failed");
    }
  };
  useEffect(() => {
    try {
      getAuth();
    } catch (error) {}
  }, []);
  const getModules = async () => {
    const { data } = await axios.get(`/api/admin/all-modules/${subjectId}`);

    setModules(data.modules);
  };
  const getSubjectDetail = async () => {
    const { data } = await axios.get(`/api/admin/subject-detail/${subjectId}`);

    setSubjectDetail(data.subjectDetail);
    setLoading(false);
  };
  const getSortedModules = async () => {
    setLoading(true);
    const { data } = await axios.get(`/api/admin/sortedModules/${subjectId}`);

    setModules(data.modules);
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    try {
      getModules();
      getSubjectDetail();
    } catch (error) {
      alert.error(error);
      setLoading(false);
    }
  }, [alert, isHide]);

  const setModule = () => {
    const data = {
      columns: [
        {
          label: "Id",
          field: "id",
          sort: "asc",
        },
        {
          label: "Position",
          field: "position",
          sort: "asc",
        },

        {
          label: "Title",
          field: "title",
          sort: "asc",
        },

        {
          label: "Content",
          field: "content",
          sort: "asc",
        },
       
        {
          label: "Type",
          field: "type",
          sort: "asc",
        },
        {
          label: "Published",
          field: "published",
          sort: "asc",
        },
        {
          label: "Preview",
          field: "preview",
          sort: "asc",
        },
        {
          label: "Action",
          field: "actions",
        },
      ],
      rows: [],
    };

    {
      modules &&
        modules.forEach((module) => {
          data.rows.push({
            title: module.title,
            id: module.cms_syllabus_id ?<div>
            <p className="py-1 ">
              {module.id}
              </p>
          </div> :(
              <div>
                <Link to={`/admin/all-lectures/${module.id}`} className="py-1 ">
                  {module.id}
                </Link>
              </div>
            ),
            position: (
              <div>
                <button
                  className="btn py-1 px-2  "
                  onClick={() => positionIncrease(module.id)}
                >
                  <i className="fa fa-plus"></i>
                </button>
                <p className="font-weight-bold px-2 py-1">{module.position}</p>
                <button
                  className="btn py-1 px-2  "
                  onClick={() => positionDecrease(module.id)}
                >
                  <i className="fa fa-minus"></i>
                </button>
              </div>
            ),
            content: module.cms_syllabus_id ? "CMS": "PDF",
            type: module.type,
      
            preview: module.preview ? (
              <button
                className="btn btn-success py-1 px-2 my-2 "
                onClick={() => previewHandler(module.id)}
              >
                <i className="fa fa-check"></i>
              </button>
            ) : (
              <button
                onClick={() => previewHandler(module.id)}
                className="btn btn-danger py-1 px-2 my-2 "
              >
                <i className="fa fa-times"></i>
              </button>
            ),
            published: module.published ? (
              <button
                className="btn btn-success py-1 px-2 my-2 "
                onClick={() => hideHandler(module.id)}
              >
                <i className="fa fa-check"></i>
              </button>
            ) : (
              <button
                onClick={() => hideHandler(module.id)}
                className="btn btn-danger py-1 px-2 my-2 "
              >
                <i className="fa fa-times"></i>
              </button>
            ),
            actions:module.cms_syllabus_id ? "visit CMS website for doing updations" :(
              <div>
                <Link
                  to={`/admin/module/${module.id}`}
                  className="btn btn-primary py-1 px-2 "
                >
                  <i className="fa fa-pencil"></i>
                </Link>
              </div>
            ),
          });
        });
    }

    return data;
  };

  return (
    <div>
      <MetaData title={"Modules"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="col-12 col-md-10">
            <div>
              <h2 className="my-2">Modules</h2>
              <h4 className="my-2">{subjectDetail && subjectDetail.name}</h4>
              <h5 className="my-2">
                {subjectDetail &&
                  subjectDetail.semester + "/" + subjectDetail.course}
              </h5>
              <h6 className="">
                {subjectDetail &&
                  subjectDetail.university +
                    " - " +
                    subjectDetail.state +
                    " (" +
                    subjectDetail.stream +
                    ")"}
              </h6>

              <Link
                to={`/admin/add-module/${subjectId}`}
                className="btn btn-success py-1 px-2 my-2 "
              >
                Add Module
              </Link>

              <Link
                to={`/admin/all-subjects/${subjectDetail.semester_id}`}
                className="btn btn-info py-1 px-2 my-2 mx-2"
              >
                Back to Subjects
              </Link>
              <button
                className="btn btn-success py-1 px-2 my-2 "
                onClick={() =>getSortedModules()}
              >
                Check by Position
              </button>
              <div>
                <MDBDataTable
                  data={setModule()}
                  className="py-1"
                  bordered
                  striped
                  hover
                  paging={false}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllModules;
