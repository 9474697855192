import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";

import Loader from "../../layout/Loader";

import MetaData from "../../layout/MetaData";

import { useAlert } from "react-alert";

import Sidebar from "../Sidebar";
import axios from "axios";
import Resizer from "react-image-file-resizer";

const AllReviewVideos = ({ history, match }) => {
  const alert = useAlert();
  const [reviewVideos, setReviewVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const getAuth = async () => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const { data } = await axios.get("/api/admin/auth-admin", config);
    } catch (error) {
      alert.error(error.response.data);
      history.push("/login");
    }
  };
  const reviewVideoDeleteHandler = async (reviewVideoId, key) => {
    if (window.confirm("Are you sure?")) {
      try {
        await axios.post("/api/s3/delete-image", { key: key });
        await axios.delete(`/api/admin/reviewVideo/${reviewVideoId}`);
        setIsDeleted(!isDeleted);
        alert.success("deleted successfully");
        history.push(`/admin/review-videos`);
      } catch (error) {
        alert.error("deletion failed");
      }
    }
  };

  useEffect(() => {
    try {
      getAuth();
    } catch (error) {}
  }, []);
  const getReviewVideos = async () => {
    const { data } = await axios.get(`/api/admin/review-videos`);

    setReviewVideos(data.reviewVideos);
  };

  useEffect(() => {
    setLoading(true);
    try {
      getReviewVideos();
      setLoading(false);
    } catch (error) {
      alert.error(error);
      setLoading(false);
    }
  }, [alert, isDeleted]);

  const setReviewVideo = () => {
    const data = {
      columns: [
        {
          label: "Id",
          field: "id",
          sort: "asc",
        },
        {
          label: "Title",
          field: "title",
          sort: "asc",
        },

        {
          label: "Thumbnail",
          field: "thumbnail",
          sort: "asc",
        },

        {
          label: "Video",
          field: "video",
          sort: "asc",
        },

        {
          label: "Action",
          field: "actions",
        },
      ],
      rows: [],
    };

    {
      reviewVideos &&
        reviewVideos.forEach((reviewVideo) => {
          data.rows.push({
            title: reviewVideo.title,
            id: reviewVideo.id,

            thumbnail: (
              <img
                src={`https://chaloexam.s3.amazonaws.com/${reviewVideo.thumbnail}`}
                alt=""
                border="0"
                height="75"
                width="75"
              />
            ),
            video: (
              <a href={`${reviewVideo.video}`} target="_blank">
                {" "}
                click to see video{" "}
              </a>
            ),
            actions: (
              <div>
                <button
                  title="Delete"
                  className="btn btn-danger py-1 px-2 mx-2"
                  type="button"
                  onClick={() =>
                    reviewVideoDeleteHandler(
                      reviewVideo.id,
                      reviewVideo.thumbnail
                    )
                  }
                >
                  <i className="fa fa-trash"></i>
                </button>
              </div>
            ),
          });
        });
    }

    return data;
  };

  return (
    <div>
      <MetaData title={"Review Videos"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div className="col-12 col-md-10">
            <div>
              <h1 className="my-2">Review Videos</h1>

              <Link
                to={`/admin/add-reviewVideo`}
                className="btn btn-success py-1 px-2 my-2 "
              >
                Add Review Video
              </Link>

              <div>
                <MDBDataTable
                  data={setReviewVideo()}
                  className="px-3"
                  bordered
                  striped
                  hover
                  paging={false}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllReviewVideos;
