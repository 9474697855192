import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";

import Loader from "../../layout/Loader";

import MetaData from "../../layout/MetaData";

import { useAlert } from "react-alert";

import Sidebar from "../Sidebar";
import axios from "axios";


const AllSchemes = ({history}) => {
  const alert = useAlert();
  const [schemes, setSchemes] = useState([]);
  const [loading, setLoading] = useState(false);
 
  const getSchemes = async () => {
    const { data } = await axios.get("/api/admin/all-schemes");

    setSchemes(data.schemes);
    setLoading(false);
  };
  const getAuth = async () => {
    const token = localStorage.getItem("token");
    const jwtToken = JSON.parse(token);
    const config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };
    try {
      const { data } = await axios.get("/api/admin/auth-admin", config);
    } catch (error) {
      alert.error(error.response.data);
      history.push("/login");
    }
  };

  useEffect(() => {
    try {
      getAuth();
    } catch (error) {}
  }, []);
  useEffect(() => {
    setLoading(true);
    try {
      getSchemes();
    } catch (error) {
      alert.error(error);
    }
  }, [alert]);

  const setScheme = () => {
    const data = {
      columns: [
        {
          label: "Scheme Id",
          field: "id",
          sort: "asc",
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
        },
        {
          label: "Action",
          field: "actions",
        },
      ],
      rows: [],
    };

    {
      schemes &&
        schemes.forEach((scheme) => {
          data.rows.push({
            name: scheme.name,
            id: scheme.id,
            actions: (
              <div>
                <Link
                  to={`/admin/scheme/${scheme.id}`}
                  className="btn btn-primary py-1 px-2 "
                >
                  <i className="fa fa-pencil"></i>
                </Link>
              </div>
            ),
          });
        });
    }

    return data;
  };

  return (
    <div>
      <MetaData title={"All Schemes"} />
      <div className="row">
        <div className="col-12 col-md-2">
          <Sidebar />
        </div>
        <div className="col-12 col-md-10">
          <div>
            <h1 className="my-5"> All Schemes</h1>
            {loading ? (
              <Loader />
            ) : (
              <div>
                <MDBDataTable
                  data={setScheme()}
                  className="px-3"
                  bordered
                  striped
                  hover
                  paging={false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllSchemes;
